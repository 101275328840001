<template>
<div class= "outerDiv">
    <div class="home">
    <div class = "image-container">
        <img alt="Ragon" src="@/assets/pictures/ragon.jpeg">
        </div>
    <h1>{{introduction}}</h1>
    <p>{{name}}</p>
    <p>{{purpose}}</p>
    <p>{{search}}</p>
    <p>{{gift}}</p>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    introduction: String,
    name: String,
    purpose: String,
    search: String,
    gift: String
  }
}
</script>

<style>


.image-container {
  width: 100%;
  padding: 0;
  position: relative;
}
img {
    padding: 0;
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
}
</style>
