<template>
  <Shaman
    v-if="(!lang && !(language == 'es' || language == 'de')) || (lang && !(lang == 'es' || lang == 'de'))"
    :question="text1en"
    :introduction="text2en"
    :sansula="text3en"
    :knife="text4en"
    :phurba="text5en"
    :investment="text6en"
    :book = "text7en"
  />
  <Shaman
    v-if="lang == 'es' || (!lang && language == 'es')"
    :question="text1es"
    :introduction="text2es"
    :sansula="text3es"
    :knife="text4es"
    :phurba="text5es"
    :investment="text6es"
    :book = "text7es"

  />
  <Shaman
    v-if="lang == 'de' || (!lang && language == 'de')"
    :question="text1de"
    :introduction="text2de"
    :sansula="text3de"
    :knife="text4de"
    :phurba="text5de"
    :investment="text6de"
    :book = "text7de"
  />
</template>


<script>
// @ is an alias to /src
import Shaman from "@/components/Shaman.vue";

export default {
  name: "ShamanView",
  components: {
    Shaman,
  },
  props: ["lang"],
  data() {
    
    return {
      language : navigator.language.slice(0,2),
      //english text
      text1en: "What does Shamanism mean?",
      text2en: "Shamanism means to feel your own soul. To feel your own soul means to free yourself. To free yourself from dependencies. To free yourself from expectations and perceptions.",
      text3en:
        "With the Sansula I open this space inside you, for you. So you can have a connection with your own soul and with yoursel",     
      text4en:"With the shamanic knife, I dissolve your dependencies. To other persons. To things and to expectations.",
      text5en:"With the Phurba, I fill your chakras. Your physical and your astral body with the light of your own origin.",
      text6en:"Investment: 200 $",
      text7en: "Book",
      //german text
      text1de: "Was bedeutet Schamanismus?",
      text2de: "Schamanismus bedeutet, seine eigene Seele zu spüren. Seine eigene Seele zu spüren bedeutet sich zu befreien. Von Abhängigkeiten. Von Erwartungen und Vorstellungen.",
      text3de:
        "Mit der Sansula öffne ich diesen Raum in dir, für dich. Damit du eine Verbindung hast mit deiner eigenen Seele und mit dir selbst.",
      text4de:
        "Mit dem schamanischem Messer löse ich deine Abhängigkeiten auf. Zu anderen Personen. Zu Dingen und zu Erwartungen.",
      text5de: "Mit der Phurba fülle ich deine Chakras, deinen physischen Körper und deinen Astral Körper mit dem Licht deines eigenen Ursprungs.",
      text6de: "Investition: 200 €",
      text7de: "Buchen",
      //spanish text
      text1es: "¿Que significa chamanismo?",
      text2es: "Chamanismo significa sentir su misma alma. Sentir su misma alma significa liberarse. Liberarse de sus dependencias. Liberarse de sus pensamientos y sus expectativas. ",
      text3es:
        "Con la sansula abro ese espacio para ti. Para qué tengas una conexión con tu alma, y contigo mismo.",
      text4es:
        "Con el cuchillo chamán resuelvo tus dependencias. A otras personas. A cosas y previsiones. ",
      text5es:"Con la phurba lleno tus chakras. Tu cuerpo físico y astral con la luz de tu origen.",
      text6es:"Inversión: 200 Soles",
      text7es : "Comprar"
      };
  },
};
</script>
