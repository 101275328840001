import { createRouter, createWebHashHistory } from 'vue-router'
import Home from "@/views/HomeView"
import Consultation from "@/views/ConsultationView"
import Shaman from "@/views/ShamanView"
import FengShui from "@/views/FengShuiView"
import Contact from "@/views/ContactView"
import Chakra from "@/views/ChakraView"
import DSGVO from "@/views/DsgvoView"

var lang = navigator.language.slice(0, 2)

const routes = [
  {
    path: '/',
    redirect: '/' + lang + '/home',

  },
  {
    path: '/:lang/home',
    name: 'home2',
    props: true,
    component: Home
  }, {
    path: '/consultation',
    redirect: '/' + lang + '/consultation',
  },
  {
    path: '/:lang/consultation',
    name: 'consultation',
    props: true,
    component: Consultation
  },
  {
    path: '/FengShui',
    props: true,
    redirect: '/' + lang + '/fengshui',
  },
  {
    path: '/:lang/FengShui',
    props: true,

    name: 'fengShui',
    component: FengShui
  },
  {
    path: '/:lang/shaman',
    props: true,

    name: 'shaman',
    component: Shaman
  },
  {
    path: '/Shaman',
    redirect: '/' + lang + '/shaman',
  },
  {
    path: '/:lang/shaman',
    props: true,

    name: 'shaman',
    component: Shaman
  },
  {
    path: '/Chakra',
    redirect: '/' + lang + '/chakra',
  },
  {
    path: '/:lang/chakra',
    props: true,

    name: 'chakra',
    component: Chakra
  },
  {
    path: '/:lang/contact',
    props: true,
    name: 'contact',
    component: Contact
  },
  {
    path: '/:lang/dsgvo',
    props: true,
    name: 'dsgvo',
    component: DSGVO
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
