<template>
  <div class="outerDiv">
    <div class="image-container">
      <p>{{ introduction }}</p>
      <p>{{ camino }}</p>
   
      <div
        class="card-carousel"
        @mouseover="stopTimer"
        @mouseleave="restartTimer"
      >
        <div class="progressbar" v-if="autoSlideInterval && showProgressBar">
          <div :style="{ width: progressBar + '%' }"></div>
        </div>
        <div class="card-img">
          <img :src="currentImage" alt="" />
          
        </div>
        <div class="thumbnails">
          <div
            v-for="(image, index) in images"
            :key="image.id"
            :class="['thumbnail-image', activeImage == index ? 'active' : '']"
            @click="activateImage(index)"
          >
            <img :src="image.thumb" />
          </div>
        </div>
      </div>

      <p>{{ fire }}</p>
      <p id="enjoy">{{ investment }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Chakra",
  props: {
    introduction: String,
    camino: String,
    fire: String,
    investment: String,
  },
  data() {
    return {
      //Array to hold all carousel images
      images: [
        {
          id: "1",
          big: require("@/assets/pictures/seminar/crop/root_chakra_crop.png"),
          thumb: require("@/assets/pictures/seminar/thumbnail/root_chakra_thumbnail.png"),
        },
        {
          id: "2",
          big: require("@/assets/pictures/seminar/crop/sacral_chakra_crop.png"),
          thumb: require("@/assets/pictures/seminar/thumbnail/sacral_chakra_thumbnail.png"),
        },
        {
          id: "3",
          big: require("@/assets/pictures/seminar/crop/solarplexus_chakra_crop.png"),
          thumb: require("@/assets/pictures/seminar/thumbnail/solarplexus_chakra_thumbnail.png"),
        },
        {
          id: "4",
          big: require("@/assets/pictures/seminar/crop/heart_chakra_crop.png"),
          thumb: require("@/assets/pictures/seminar/thumbnail/heart_chakra_thumbnail.png"),
        },
        {
          id: "5",
          big: require("@/assets/pictures/seminar/crop/rose_chakra_crop.png"),
          thumb: require("@/assets/pictures/seminar/thumbnail/rose_chakra_thumbnail.png"),
        },
        {
          id: "6",
          big: require("@/assets/pictures/seminar/crop/throat_chakra_crop.png"),
          thumb: require("@/assets/pictures/seminar/thumbnail/throat_chakra_thumbnail.png"),
        },
        {
          id: "7",
          big: require("@/assets/pictures/seminar/crop/forehead_chakra_crop.png"),
          thumb: require("@/assets/pictures/seminar/thumbnail/forehead_chakra_thumbnail.png"),
        },
        {
          id: "8",
          big: require("@/assets/pictures/seminar/crop/lotus_chakra_crop.png"),
          thumb: require("@/assets/pictures/seminar/thumbnail/lotus_chakra_thumbnail.png"),
        },
      ],
      //Index of the active image on the images array
      activeImage: 0,
    };
  },
  computed: {
    // currentImage gets called whenever activeImage changes
    // and is the reason why we don't have to worry about the
    // big image getting updated
    currentImage() {
      return this.images[this.activeImage].big;
    },
  },
  methods: {
    // Go forward on the images array
    // or go at the first image if you can't go forward :/
    nextImage() {
      var active = this.activeImage + 1;
      if (active >= this.images.length) {
        active = 0;
      }
      this.activateImage(active);
    },
    // Go backwards on the images array
    // or go at the last image
    prevImage() {
      var active = this.activeImage - 1;
      if (active < 0) {
        active = this.images.length - 1;
      }
      this.activateImage(active);
    },
    activateImage(imageIndex) {
      this.activeImage = imageIndex;
    },
  },
};
</script>

<style>
#enjoy {
  text-align: center;
}
.container {
  display: flex;
  justify-content: center;
  width: 100%;
}

iframe {
  max-width: 900px;
  max-height: 600px;
  width: 100%;
  min-height: 300px;
}

.image-container {
  width: 100%;
  padding: 0;
  position: relative;
}
img {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}

.card-carousel {
  user-select: none;
  position: relative;
}

.progressbar {
  display: block;
  width: 100%;
  height: 5px;
  position: absolute;
  background-color: rgba(221, 221, 221, 0.25);
  z-index: 1;
}

.progressbar > div {
  background-color: rgba(255, 255, 255, 0.52);
  height: 100%;
}

.thumbnails {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.thumbnail-image {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  height: 50px;
}

.thumbnail-image > img {
  width: 100%;
  height: auto;
  transition: all 250ms;
}

.thumbnail-image:hover > img,
.thumbnail-image.active > img {
  opacity: 0.6;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.5);
}

.card-img {
  position: relative;
  margin-bottom: 15px;
}

.card-img > img {
  display: block;
  margin: 0 auto;
}

.actions {
  font-size: 4.5em;
  height: 40px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #585858;
}

.actions > span {
  cursor: pointer;
  transition: all 250ms;
}

.actions > span.prev {
  margin-left: 5px;
}

.actions > span.next {
  margin-right: 5px;
}

.actions > span:hover {
  color: #eee;
}
</style>
