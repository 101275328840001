<template>
  <DSGVO
    v-if="(!lang && !(language == 'es' || language == 'de')) || (lang && !(lang == 'es' || lang == 'de'))"
    :o="text1en"
    :happy="text2en"
    
  />
  <DSGVO
    v-if="lang == 'es' || (!lang && language == 'es')"
    :o="text1es"
    :happy="text2es"
    
  />
  <DSGVO
    v-if="lang == 'de' || (!lang && language == 'de')"
    :o="text1de"
    :happy="text2de"
  />
</template>


<script>
// @ is an alias to /src
import DSGVO from "@/components/DSGVO.vue";

export default {
  name: "DsgvoView",
  components: {
    DSGVO,
  },
  props: ["lang"],
  data() {
    
    return {
      language : navigator.language.slice(0,2),
      //english text
      text1en: "My Website doesn't collect any data whatsoever",
      text2en: "For the information about the data collection of the host allinkl please visit: ",


      //german text
      text1de: "Meine Website sammelt in keiner Art und Weise Daten über dich",
      text2de: "Für Information über die Datenverarbeitung des Hosts, Allinkl, besuche: ",

      //spanish text
      text1es: "Mi pagina no collecta ningun dato de ti",
      text2es: "Para ver la coleccion de informacion sobre Allinkl puedes visitar: ",
      };
  },
};
</script>
