<template>
<div class= "outerDiv">
    <div>
    <div class = "image-container">
        
        </div>
    <p>{{introduction}}</p>
    <p>{{state}}</p>
    <p>{{interest}}</p>
    <p>{{investment}}</p>
    <div id = "buyButtonDiv"><router-link class= "buyButton"
      :lang="language"
      :to="{ name: 'contact', params: { lang: 'en' } }"
      >{{book}}</router-link></div>
      <br>
    <img src="@/assets/pictures/feng_shui.png">

  </div>
</div>

</template>

<script>
export default {
  name: 'FengShui',
  props: {
    introduction: String,
    state: String,
    interest: String,
    investment: String,
    book: String
  }
}
</script>

<style>


.image-container {
  width: 100%;
  padding: 0;
  position: relative;
}
img {
    padding: 0;
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
}
</style>
