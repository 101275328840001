<template>
  <FengShui
    v-if="(!lang && !(language == 'es' || language == 'de')) || (lang && !(lang == 'es' || lang == 'de'))"
    :introduction="text1en"
    :state="text2en"
    :interest="text3en"
    :investment="text4en"
    :book = "text5en"
  />
  <FengShui
    v-if="lang == 'es' || (!lang && language == 'es')"
    :introduction="text1es"
    :state="text2es"
    :interest="text3es"
    :investment="text4es"
    :book = "text5es"
  />
  <FengShui
    v-if="lang == 'de' || (!lang && language == 'de')"
    :introduction="text1de"
    :state="text2de"
    :interest="text3de"
    :investment="text4de"
    :book = "text5de"
  />
</template>


<script>
// @ is an alias to /src
import FengShui from "@/components/FengShui.vue";

export default {
  name: "FengShuiView",
  components: {
    FengShui,
  },
  props: ["lang"],
  data() {
    
    return {
      language : navigator.language.slice(0,2),
      //english text
      text1en: "Every one of us knows the sensation of entering a room and immediately feeling relaxed and comfortable. Or stressed. Some places literally pull us to them. We always want to go back to them. In other places not so much. We would rather not visit them.",
      text2en: "This state is strongly connected with the energy of the place. Feng Shui is a method to change this energy consciously. For example to create a place of peace and calmness in an office. Or to create a warm and comfortable atmosphere in a living room.",
      text3en:
        "Are you interested in this offer? Then write me a WhatsApp or an E-Mail and we have a look at how we can change the energy in one of your rooms or your apartment.",
      text4en:
        "Investment: Starting at 70 $.",
      text5en: "Book",
      //german text
      text1de: "Jeder von uns kennt das Gefühl, einen Raum zu betreten und sich direkt wohl zu fühlen. Oder direkt schlecht gelaunt. Manche Orte ziehen uns regelrecht an. Wir wollen immer wieder an sie zurückkehren. Vor anderen Orten haben wir Angst und wir meiden sie lieber.",
      text2de: "Dieser Zustand ist stark verbunden mit der Energie des Ortes. Feng Shui ist eine Methode, diese Energie bewusst zu ändern. Zum Beispiel in einem Büro einen Raum von Frieden und Ruhe zu erzeugen. Oder in eine gemütliche und warme Atmosphäre in einem Wohnzimmer.",
      text3de:
        "Bist du interessiert an diesem Angebot? Dann schreibe mir über WhatsApp oder per E-Mail und wir schauen uns gemeinsam an, wie wir die Energie in einem deiner Räume oder deiner Wohnung verändern können.",
      text4de:
        "Investition: Ab 70 €",
      text5de: "Buchen",
      //spanish text
      text1es: "Nosotros todos conocemos el sentimiento de entrar a un lugar y sentir muy cómodo. O muy estresado. Algunos lugares nos gustan mucho. Siempre tenemos el deseo de regresar. De algunos lugares tenemos miedo. No queremos regresar a esos",
      text2es: "Ese estado del lugar está conectado con la energía del lugar. Feng Shui es el trabajo de cambiar esa energía conscientemente. Por ejemplo crear paz y tranquilidad en la oficina. Establecer cómodo y una atmósfera caliente en la sala.",
      text3es:
        "¿Tú estás interesada en esa oferta? Escríbeme por WhatsApp o correo electrónico y vemos como podemos cambiar la energía en tu lugar especial.",
      text4es:
        "Inversión: 70 Soles",
      text5es: "Comprar"
      };
  },
};
</script>
