<template>
<div class= "outerDiv">
  <div id = "contact">
    <p>Whatsapp: +49 17645 920687</p>
    <p>{{ o }}</p>
    <p>contact@ragonebker.com</p>
    <p>Ragon Ebker </p>
    <p> Innstraße 77b 94036 Passau</p>
    <img id="ragon" src="@/assets/pictures/contact.jpeg" />
    <p id="happy">{{ happy }}</p>
    <div id="social">
      <a class = "socialLogo" href="https://www.instagram.com/ragonebker/">
        <img src="@/assets/pictures/instagram.svg" />
      </a>
      <a class = "socialLogo" href="https://www.facebook.com/raphael.ebker">
        <img src="@/assets/pictures/facebook.svg" />
      </a>
      <a class = "socialLogo" href="https://www.youtube.com/channel/UCaNlNxBebgaWL5dSfTD4sAA">
        <img src="@/assets/pictures/youtube.svg" />
      </a>

    </div>
  </div>


</div>

</template>

<script>
export default {
  name: "Contact",
  props: {
    happy: String,
    o: String,
  },
};
</script>

<style>
.socialLogo {
 display: inline-block;
    margin:20px;
    height: 50px; 
    width:50px;
    
}



#social {
  text-align: center;
}
#ragon {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 70%;
}
#contact {
  text-align:center;
}
a.svg {
  position: relative;
  display: inline-block;
}
a.svg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
body {

  text-align: center;
  display: block;
}


.bi-twitter {
  color: #55acee;
  margin: 5px 20px;
}

.bi-google {
  color: #dd4b39;
  margin: 5px 20px;
}

.bi-youtube {
  color: #dd4b39;
  margin: 5px 20px;
}


.image-container {
  width: 100%;
  padding: 0;
  position: relative;
}

#happy {
  text-align: center;
}

.fa {
  padding: 20px;
  font-size: 30px;
  height: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fa:hover {
  opacity: 0.7;
}
.fa-instagram {
  background: #125688;
  color: white;
  padding-top: 20px;
}
.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}
</style>
