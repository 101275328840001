<template>
<div class= "outerDiv">
  <br>
  <p>{{ o }}</p>
  <br>
  {{ happy }}
  <br>
  <br>
  <a href = "https://all-inkl.com/datenschutzinformationen/">All Inkl Datenschutzinformationen</a>
  <br>
</div>

</template>

<script>
export default {
  name: "dsgvo",
  props: {
    happy: String,
    o: String,
  },
};
</script>

<style>
.socialLogo {
 display: inline-block;
    margin:20px;
    height: 50px; 
    width:50px;
    
}



#social {
  text-align: center;
}
#ragon {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 70%;
}
#contact {
  text-align:center;
}
a.svg {
  position: relative;
  display: inline-block;
}
a.svg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
body {

  text-align: center;
  display: block;
}


.bi-twitter {
  color: #55acee;
  margin: 5px 20px;
}

.bi-google {
  color: #dd4b39;
  margin: 5px 20px;
}

.bi-youtube {
  color: #dd4b39;
  margin: 5px 20px;
}


.image-container {
  width: 100%;
  padding: 0;
  position: relative;
}

#happy {
  text-align: center;
}

.fa {
  padding: 20px;
  font-size: 30px;
  height: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fa:hover {
  opacity: 0.7;
}
.fa-instagram {
  background: #125688;
  color: white;
  padding-top: 20px;
}
.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}
</style>
