<template>
  <Home
    v-if="(!lang && !(language == 'es' || language == 'de')) || (lang && !(lang == 'es' || lang == 'de'))"
    :introduction="text1en"
    :name="text2en"
    :purpose="text3en"
    :search="text4en"
    :gift="text5en"
  />
  <Home
    v-if="lang == 'es' || (!lang && language == 'es')"
    :introduction="text1es"
    :name="text2es"
    :purpose="text3es"
    :search="text4es"
    :gift="text5es"
  />
  <Home
    v-if="lang == 'de' || (!lang && language == 'de')"
    :introduction="text1de"
    :name="text2de"
    :purpose="text3de"
    :search="text4de"
    :gift="text5de"
  />
</template>


<script>
// @ is an alias to /src
import Home from "@/components/Home.vue";

export default {
  name: "HomeView",
  components: {
    Home,
  },
  props: ["lang"],
  data() {
    
    return {
      language : navigator.language.slice(0,2),
      //english text
      text1en: "Hello",
      text2en: "My Name is Ragon",
      text3en:
        "My purpose is, to help you return to your own inner source of happines and oneness. To create a space in yourself that makes you feel safe and loved no matter where you go or what you do.",
      text4en:
        "We have a passion for searching. We accumulate wealth, friends, relationships and habits. This will make me happy, no wait, this will make me happy.",
      text5en:
        "What really makes us happy, what really gives us purpose and fullfillment in life, is a gift. A gift to learn to unwrap, to value and to nourish.",

      //german text
      text1de: "Hallo",
      text2de: "Mein Name ist Ragon.",
      text3de:
        "Meine Aufgabe ist es, dir dabei zu helfen, zu deiner eigenen inneren Quelle von Glücklichsein und Einheit zurückzukehren. Einen Raum in dir selbst zu schaffen, in dem du dich sicher fühlst, egal wohin du gehst oder was du machst.",
      text4de:
        "Wir Menschen haben manchmal eine Leidenschaft dafür zu suchen. Wir häufen Wohlstand, Freunde, Beziehungen und Gewohnheiten an. Hey, das macht mich glücklich. Nein, das macht mich glücklich.",
      text5de:
        "Was uns wirklich glücklich macht und uns Sinn und Erfüllung in unserem Leben gibt ist ein Geschenk. Ein Geschenk das wir lernen müssen, auszupacken, wertzuschätzen und zu nähren.",

      //spanish text
      text1es: "Hola",
      text2es: "Mi nombre es Ragon",
      text3es:
        "Mi propósito es ayudarte a regresar a tu propia fuente interna de felicidad y unidad. Crear un espacio en ti mismo que te haga sentir seguro y amado sin importar a dónde vayas o lo que hagas.",
      text4es:
        "Tenemos una pasión por la búsqueda. Acumulamos riqueza, amigos, relaciones y hábitos. Esto me hará feliz, no espere, esto me hará feliz.",
      text5es:
        "Lo que realmente nos hace felices, lo que realmente nos da propósito y plenitud en la vida, es un regalo. Un regalo para aprender a desenvolver, valorar y nutrir.",
    };
  },
};
</script>
