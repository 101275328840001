<template>
  <Chakra
    v-if="(!lang && !(language == 'es' || language == 'de')) || (lang && !(lang == 'es' || lang == 'de'))"
    :introduction="text1en"
    :camino="text2en"
    :fire="text3en"
    :investment="text4en"

  />
  <Chakra
    v-if="lang == 'es' || (!lang && language == 'es')"
    :introduction="text1es"
    :camino="text2es"
    :fire="text3es"
    :investment="text4es"

  />
  <Chakra
    v-if="lang == 'de' || (!lang && language == 'de')"
    :introduction="text1de"
    :camino="text2de"
    :fire="text3de"
    :investment="text4de"

  />
</template>


<script>
// @ is an alias to /src
import Chakra from "@/components/Chakra.vue";

export default {
  name: "ChakraView",
  components: {
    Chakra,
  },
  props: ["lang"],
  data() {
    
    return {
      language : navigator.language.slice(0,2),
      //english text
      text1en: "The seven chakras symbolize the seven energy centers in our body. They provide our system with light and make our human experience possible in the first place. ",
      text2en: "Each chakra can be assigned to a specific part of our energy system. Hold the respective picture in front of one of your chakras to experience the healing of the respective chakra.",
      text3en:
        "Don't worry if a few unpleasant impulses come up first. After 2-3 minutes you can switch to the next chakra.",
      text4en:
        "Enjoy",
      //german text
      text1de: "Die sieben Chakren symbolisieren die sieben Energiezentren in unserem Körper. Sie versorgen unser System mit Licht und machen unsere menschliche Erfahrung überhaupt erst möglich.",
      text2de: "Jedes Chakra lässt sich einem bestimmten Teil unseres Energiesystems zuordnen. Halte das jeweilige Bild vor eines deiner Chakren, um die Heilung des jeweiligen Chakras zu erfahren.",
      text3de:
        "Mache dir keine Sorgen wenn vielleicht erstmal ein paar unangehneme Impulse hochkommen. Nach 2-3 Minuten kannst du zum nächsten Chakra wechseln.",
      text4de:
        "Geniesse es",
      //spanish text
      text1es: "Los siete chakras simbolizan los siete centros de energía de nuestro cuerpo. Distribuyen luz a nuestro sistema y hacen posible nuestra experiencia humana en primer lugar.",
      text2es: "Cada chakra se puede asignar a una parte específica de nuestro sistema de energía. Sostenga la imagen respectiva frente a uno de sus chakras para experimentar la curación del chakra respectivo.",
      text3es:
        "No te preocupes si primero surgen algunos impulsos desagradables. Después de 2 o 3 minutos, puedes cambiar al siguiente chakra.",
      text4es:
        "Estas invitado"
      };
  },
};
</script>
