<template>
  <nav id="navbar">
    <img id="logo" src="@/assets/pictures/logoTriangle.png" />
    <router-link
      :lang="language"
      :to="{ name: 'home2', params: { lang: language } }"
      >Home</router-link
    >
    <router-link
      :lang="language"
      :to="{ name: 'consultation', params: { lang: language } }"
      >{{ Consultation[language] }}</router-link
    >
    <router-link
      :lang="language"
      :to="{ name: 'shaman', params: { lang: language } }"
      >{{ Shaman[language] }}</router-link
    >
    <br>
    <br>
     <router-link
      :lang="language"
      :to="{ name: 'chakra', params: { lang: language } }"
      >{{Chakra[language]}}</router-link
    >

    <br />
    <!-- <router-link to="/shaman">{{ Consultation[language] }}</router-link> -->
    <br />
    <router-link
      :lang="language"
      :to="{ name: 'fengShui', params: { lang: language } }"
      >Feng Shui</router-link
    >
    <router-link
      :lang="language"
      :to="{ name: 'contact', params: { lang: language } }"
      >{{ Contact[language] }}</router-link
    >
    <router-link
      :lang="language"
      :to="{ name: 'dsgvo', params: { lang: language } }"
      >DSGVO</router-link
    >
    <br />
  </nav>
  <div id="router"><router-view /></div>
  <div id="hr">
    <hr />
  </div>

  <br />
  <footer>
    <router-link
      @click="language = 'es'"
      :class="{ selectedLang: language === 'es' }"
      :to="{ name: 'home2', params: { lang: 'es' } }"
      >Español</router-link
    >
    <router-link
      @click="language = 'en'"
      :class="{ selectedLang: language === 'en' }"
      :to="{ name: 'home2', params: { lang: 'en' } }"
      >English</router-link
    >
    <router-link
      @click="language = 'de'"
      :class="{ selectedLang: language === 'de' }"
      :to="{ name: 'home2', params: { lang: 'de' } }"
      >Deutsch</router-link
    >
  </footer>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}
.outerDiv {
  margin: 0 10px;
}
#router {
  display: flex;
  max-width: 500px;
  /* margin: 0 10px; */
  text-align: left;
  margin: auto;

  width: inherit;
}
#hr {
  margin: auto;
  max-width: 500px;
}
nav {
  padding: 14px;
  text-align: center;
}
#logo {
  height: 60px;
  position: relative;
  padding-bottom: 20px;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 15px;
}

nav a.router-link-exact-active {
  color: #008d13;
}

footer {
  text-align: center;
}
footer a {
  text-align: center;
  padding: 20px;
  text-decoration: none;
  color: #2c3e50;
}
.selectedLang {
  color: #008d13;
}

hr {
  max-width: 500px;
  margin: 0 10px;
}
#buyButtonDiv {
  text-align: center;
}
.buyButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;
}
</style>

<script>
export default {
  data() {
    return {
      deg: 20,
      language: navigator.language.slice(0, 2),
      Shaman: { es: "Chamanismo", en: "Shaman", de: "Schamanismus" },
      Consultation: {
        es: "Consultaciones",
        en: "Consultation",
        de: "Beratung",
      },
      Contact: { es: "Contacto", en: "Contact", de: "Kontakt" },
      Chakra: { es: "Los siete Chakras", en: "The seven Chakras", de: "Die sieben Chakren" },

      isActive: true,
    };
  },
};
</script>
