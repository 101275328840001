<template>
  <Contact
    v-if="(!lang && !(language == 'es' || language == 'de')) || (lang && !(lang == 'es' || lang == 'de'))"
    :o="text1en"
    :happy="text2en"
    
  />
  <Contact
    v-if="lang == 'es' || (!lang && language == 'es')"
    :o="text1es"
    :happy="text2es"
    
  />
  <Contact
    v-if="lang == 'de' || (!lang && language == 'de')"
    :o="text1de"
    :happy="text2de"
  />
</template>


<script>
// @ is an alias to /src
import Contact from "@/components/Contact.vue";

export default {
  name: "ContactView",
  components: {
    Contact,
  },
  props: ["lang"],
  data() {
    
    return {
      language : navigator.language.slice(0,2),
      //english text
      text1en: "or",
      text2en: "See you soon",


      //german text
      text1de: "oder",
      text2de: "Schön, dass es dich gibt",

      //spanish text
      text1es: "o",
      text2es: "Yo veo tu luz",
      };
  },
};
</script>
