<template>

    <div class = "outerDiv">
    <div class = "image-container">
        
        </div>
    <p>{{introduction}}</p>
    <p>{{camino}}</p>
    <p>{{fire}}</p>
    <p>{{investment}}</p>
    <div id = "buyButtonDiv"><router-link class= "buyButton"
      :lang="language"
      :to="{ name: 'contact', params: { lang: 'en' } }"
      >{{book}}</router-link></div>
      <br>
    <img alt="Ragon" src="@/assets/pictures/flame.jpeg">
    <br><br>
  </div> 
</template>

<script>
export default {
  name: 'Consultation',
  props: {
    introduction: String,
    camino: String,
    fire: String,
    investment: String,
    book : String
  }
}
</script>

<style>
.container {
  display: flex;
  justify-content: center;
  width:100%
}

iframe {
  max-width: 900px;
  max-height: 600px;
  width:100%;
  min-height:300px;
}

.image-container {
  width: 100%;
  padding: 0;
  position: relative;
}
img {
    padding: 0;
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
}
</style>
