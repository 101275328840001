<template>
  <Consultation
    v-if="(!lang && !(language == 'es' || language == 'de')) || (lang && !(lang == 'es' || lang == 'de'))"
    :introduction="text1en"
    :camino="text2en"
    :fire="text3en"
    :investment="text4en"
    :book = "text5en"
  />
  <Consultation
    v-if="lang == 'es' || (!lang && language == 'es')"
    :introduction="text1es"
    :camino="text2es"
    :fire="text3es"
    :investment="text4es"
    :book = "text5es"
  />
  <Consultation
    v-if="lang == 'de' || (!lang && language == 'de')"
    :introduction="text1de"
    :camino="text2de"
    :fire="text3de"
    :investment="text4de"
    :book = "text5de"
  />
</template>


<script>
// @ is an alias to /src
import Consultation from "@/components/Consultation.vue";

export default {
  name: "ConsultationView",
  components: {
    Consultation,
  },
  props: ["lang"],
  data() {
    
    return {
      language : navigator.language.slice(0,2),
      //english text
      text1en: "In the consultation, I see your potential and possibilities and how you can implement them in your life. We also have a look at your personal goals and wishes.",
      text2en: "We then create a path that shows you how to arrive exactly there.",
      text3en:
        "To clean your system from conflicts and problems, that stop you from living your full potential, I use a fire ceremony. This ceremony is created exclusively for you and you can use it after the consultation to guide you further on your path.",
      text4en:
        "Investment: 700 $",
      text5en: "Book",
      //german text
      text1de: "In der Beratung schaue ich auf deine Potenziale und Qualitäten, und wie du diese in deinem Leben umsetzen kannst. Außerdem betrachten wir deine persönlichen Ziele und Wünsche.",
      text2de: "Zusammen erstellen wir dann einen Plan, wie du genau dort ankommst.",
      text3de:
        "Um dein System von Konflikten und Problemen zu befreien, verwende ich eine Feuerzeremonie. Diese erstelle ich speziell für dich und du kannst sie dann nach der Beratung wiederholen, um dich an dein Sein und deine Potenziale zu erinnern.",
      text4de:
        "Investition: 700 €",
      text5de : "Buchen",
      //spanish text
      text1es: "En la consultación veo tus potenciales y posibilidades que quieres colocar en tu vida. También investigamos tus deseos y objetivos personales ",
      text2es: "Juntos construimos un camino con cuál puedes llegar a esos. ",
      text3es:
        "Para limpiar tu sistema de conflictos y problemas que te dejan a llegar a tus deseos y objetivos uso una ceremonia de fuego. Esta ceremonia está creado especialmente para ti y lo puedes usar después de la consultación para ayudarte en tu camino.",
      text4es:
        "Inversión: 700 Soles",
      text5es: "Comprar"
      };
  },
};
</script>
