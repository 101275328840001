<template>
<div class= "outerDiv">
    <div>
    
    <h1>{{ question }}</h1>
    <p>{{introduction}}</p>
    <div class = "image-container">
        <img alt="Ragon" src="@/assets/pictures/sansula.jpeg">
        </div>
    <p>{{sansula}}</p>
    <div class = "image-container">
        <img alt="Ragon" src="@/assets/pictures/knife.jpeg">
        </div>
    <p>{{knife}}</p>
    <div class = "image-container">
        <img alt="Ragon" src="@/assets/pictures/phurba.jpeg">
        </div>
    <p>{{phurba}}</p>
    <p>{{investment}}</p>
    <div id = "buyButtonDiv"><router-link class= "buyButton"
      :lang="language"
      :to="{ name: 'contact', params: { lang: 'en' } }"
      >{{book}}</router-link></div>
    
    <br>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Shaman',
  props: {
    question: String,
    introduction: String,
    sansula: String,
    knife: String,
    phurba: String,
    investment: String,
    book: String,
  }
}
</script>

<style>


.image-container {
  width: 100%;
  padding: 0;
  position: relative;
}
img {
    padding: 0;
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
}
</style>
